<script setup lang="ts">
import type { HomeBanners } from "@/types";

type Modals = {
	LazyOModalDailyWheel: "LazyOModalDailyWheel";
	LazyOModalWheelOfTreasureWelcome: "LazyOModalWheelOfTreasureWelcome";
	LazyOModalPresetPackage: "LazyOModalPresetPackage";
};

const props = defineProps<{
	banner: HomeBanners[number];
	isGuest?: boolean;
	imgDesk: string;
	imgMob: string;
	bgDesk: string;
	bgMob: string;
	title?: string;
	smallTitle?: string;
	buttonText?: string;
	buttonLink?: string;
}>();
const router = useRouter();

const { open } = useAppModals();

const { handleOpenGame } = useOpenGame(open);

const handleBannerClick = () => {
	if (props.isGuest || props.buttonLink === "/register/") {
		open("LazyOModalSignup");
		return;
	}
	if (!props.banner.buttonLink) {
		return;
	}
	if (props.buttonLink) {
		if (props.buttonLink.includes("facebook")) {
			window.open(props.banner.buttonLink, "_blank");
			return;
		}
		if (props.buttonLink.includes("game=")) {
			const slug = props.buttonLink.replace("game=", "");
			dispatchGAEvent({
				event: "click_button",
				button_name: "money_play",
				location: slug,
				form_name: "banner"
			});
			handleOpenGame(slug);
			return;
		}

		if (props.buttonLink.includes("openModal=")) {
			const slug = props.buttonLink.replace("openModal=", "");
			open(slug as keyof Modals);
			return;
		}

		router.push(props.buttonLink);
	}
};

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
</script>

<template>
	<MBanner
		class="banner-main"
		:image="`${baseImageUrl}${props.imgDesk}`"
		:image-mobile="`${baseImageUrl}${props.imgMob}`"
		:background="`${baseImageUrl}${props.bgDesk}`"
		:background-mob="`${baseImageUrl}${props.bgMob}`"
	>
		<AText variant="tangier" :modifiers="['bold']" as="p" class="title">
			<span v-html="props.title" />
		</AText>
		<AText as="h2" variant="tashkent" class="subtitle">
			<span v-html="props.smallTitle" />
		</AText>
		<AButton variant="primary" size="xl" class="button" @click="handleBannerClick">
			<AText :modifiers="['medium']" variant="turin"> {{ props.buttonText }} </AText>
		</AButton>
		<template #button>
			<AButton variant="primary" size="xl" @click="handleBannerClick">
				<AText :modifiers="['medium']" variant="turin">{{ props.buttonText }}</AText>
			</AButton>
		</template>
	</MBanner>
</template>

<style scoped lang="scss">
.banner-main {
	&:deep(.banner-content) {
		justify-content: center;

		@include media-breakpoint-down(md) {
			justify-content: flex-end;
		}
	}
}
.title {
	@include media-breakpoint-down(md) {
		font-size: 20px;
		line-height: 26px;
		text-align: center;
	}
}

.subtitle {
	max-width: 433px;
	margin: gutter(0.5) 0;
	@include media-breakpoint-down(md) {
		margin: 0;
		font-size: 28px;
		text-align: center;
	}
}
</style>
